@charset "utf-8";

/**
 * Extremely hacked version of minima 2.5.1
 */

@import url('fonts/Montserrat/Montserrat.css');
@import url('fonts/SourceCodePro/SourceCodePro.css');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,800;1,400;1,800&family=Alegreya:ital,wght@0,500;1,500&family=Roboto+Condensed:wght@700&display=swap');

$weight-thin: 100;
$weight-extralight: 200;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;
$weight-extrabold: 800;
$weight-black: 900;

// Define defaults for each variable.

$base-font-family: 'Montserrat', sans-serif;
$code-font-family: 'Source Code Pro', monospace;
$base-font-size:   1rem !default;
$base-font-weight: $weight-normal !default;
$small-font-size:  $base-font-size / 1.2 !default; // Minor Third scaling
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;
$border-radius:    4px !default;

$text-color:       #454545 !default;
$text-color-dark: darken($text-color, 15%) !default;
$background-color: #fdfdfd !default;
$brand-color:      #674ea7 !default;
$brand-color-light: lighten($brand-color, 15%) !default;
$brand-color-dark: darken($brand-color, 15%) !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    850px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
