/**
 * Site header
 */
.site-header {
  position: fixed;
  border-top: none;
  border-bottom: 1px solid rgba(0,0,0,0.25);
  min-height: 110px;
  background: #e57695;
  width: 100%;
  z-index: 99;

  .wrapper {
    margin-top: 28px;
  }
}

.site-title {
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  font-weight: $weight-extrabold;
  vertical-align: bottom;
  font-size: 1.728rem;
  margin-top: -0.25rem;

  a {
    color: $text-color;
    text-decoration: none !important;
    &:hover {
      color: $text-color-dark;
      border-bottom: 3px solid $text-color-dark;
      .colorize {
        color: $brand-color-dark;
      }
    }
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: $weight-bold;
  vertical-align: bottom;
  .selected {
    color: $brand-color;
    border-bottom: 3px solid $brand-color;
    &:hover {
      color: $brand-color-dark;
      border-bottom: 3px solid $brand-color-dark;
    }
  }
  a {
    text-decoration: none !important;
    &:hover {
      color: $text-color-dark;
      border-bottom: 3px solid $text-color-dark;
    }
  }

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: 0 5px 0.375rem;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: $border-radius;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
footer {
  background: url(img/footer.jpg) no-repeat scroll center center;
  border-top: 1px solid rgba(255,255,255,0.33);
  padding: $spacing-unit 0;
  min-height: 60px;
  color: white;
  p {
    text-align: center;
    font-weight: $weight-semibold;
  }
}

.footer-flex-wrapper {
  display: flex;
  margin: 0 auto $spacing-unit auto;
  justify-content: space-evenly;
}

.svg-footer-icon {
  fill: white;
  display: inline-block;
  vertical-align: text-top;
}



/**
 * Page content
 */
.page-content {
  padding-bottom: $spacing-unit;
  flex: 1;
  margin-top: 110px;

  .hero {
    background: url(img/hero.jpg) no-repeat scroll center center;
    background-size: cover;
    min-height: 400px;
    position: relative;
    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    p {
      text-align: center;
      color: white;
      font-family: 'Suez One', serif;
      font-weight: $weight-extrabold;
      font-size: 1.728rem;
    }
    &.optional {
      @include media-query($on-palm) {
        display: none;
      }
    }
  }
}

.page-heading {
  font-size: 2.488rem; // h1 size
}

.e-content {
  margin-top: 2rem;
}



/**
 * Posts
 */
.post-list-heading {
  font-size: 2.074rem; // h2 size
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  color: $grey-color;
  font-size: $small-font-size;
  font-family: $base-font-family;
  font-weight: $weight-normal;
  font-style: normal;
  font-variant-ligatures: common-ligatures;
  font-variant-numeric: oldstyle-nums proportional-nums;
  font-kerning: normal;
  -moz-font-feature-settings: "liga", "clig", "onum", "pnum";
  -webkit-font-feature-settings: "liga", "clig", "onum", "pnum";
  font-feature-settings: "liga", "clig", "onum", "pnum";
  line-height: 1.5;
}

.post-link {
  display: block;
  font-size: 1.728rem; // h3 size
}

.readmore {
  a {
      padding: 4px 0.75rem;
      border: 1px solid $brand-color;
      border-radius: $border-radius;
      font-size: $small-font-size;
      font-weight: $weight-semibold;
      text-decoration: none;
      &:hover {
        background: $brand-color;
        color: white;
      }
  }
}



/**
 * Tag List
 */
.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0 0.5rem;
}

.tag-header {
  flex-basis: 100%;
}

.tag-item {
  background: $brand-color;
  padding: 4px 0.75rem;
  border: 1px solid $brand-color;
  border-radius: $border-radius;
  font-size: $small-font-size;
}

.tag-link {
  color: white !important;
  text-decoration: none;
}



/**
 * Home Page Avatar
 */
div.avatar {
  float: left;
  padding: $spacing-unit / 2 $spacing-unit $spacing-unit * 1.5 0;

  @include media-query($on-laptop) {
    float: none;
    text-align: center;
    padding: $spacing-unit / 2 $spacing-unit $spacing-unit * 1.5 0;
  }
}

img.round {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;

  @include media-query($on-palm) {
    width: 150px;
    height: 150px;
  }
}



/**
 * SVG Icon Styles
 */
.svg-icon {
  fill: white;
}

.svg-list-icon {
  fill: $brand-color;
  display: inline-block;
  vertical-align: text-top;
  padding-right: 0.33rem;
  padding-top: 1px;
}



/**
 * Portfolio
 */
img.screenshot {
  width: 170px;
  float: left;
  margin-right: $spacing-unit;
  margin-bottom: $spacing-unit;
  @include media-query($on-laptop) {
    float: none;
    display: block;
    margin: 0 auto $spacing-unit;
    text-align: center;
  }
}

.button a {
  display: block;
  float: right;
  clear: right;
  background-color: #1abc9c;
  border-radius: $border-radius;
  padding: 6px 12px;
  color: white;
  box-shadow: 4px 4px 2px grey;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: $weight-semibold;
  margin: 0.5rem 0;
  text-decoration: none;
  &:hover {
    box-shadow: none;
    transform: translate(4px, 4px);
  }
}



/**
 * Misc CSS
 */
.colorize {
  color: $brand-color;
}
.clear {
  clear: both;
}
.hidden {
  display: none !important;
}
.cert-list img {
  max-width: 296px;
}
img.right {
  float: right;
  padding-left: 1rem;
}
img.left {
  float: left;
  padding-right: 1rem;
}
